import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [{
      path: '/',
      component: () => import("@/views/Login"),
      meta: {
        showNav: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import("@/views/Login"),
      meta: {
        showNav: false
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import("@/views/Auth"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/users",
      name: 'users',
      component: () => import("@/views/Users"),
      children: [{
          path: "",
          redirect: 'userlist'
        },
        {
          path: "userlist",
          name: 'userlist',
          component: () => import("@/views/UserList"),
          meta: {
            showNav: true
          }
        },
        {
          path: 'userdetail',
          name: 'userdetail',
          component: () => import("@/views/UserDetail"),
          meta: {
            showNav: true
          }
        },
      ]
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/Orders"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/payment",
      name: "payment",
      component: () => import("@/views/Payment"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/comments",
      name: "comments",
      component: () => import("@/views/Comments"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/accounts",
      name: "accounts",
      component: () => import("@/views/Accounts"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/notifyorder",
      name: "notifyorder",
      component: () => import("@/views/NotifyOrder"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/resetpsd",
      name: "resetpsd",
      component: () => import("@/views/ResetPsd"),
      meta: {
        showNav: true
      }
    },

    {
      path: "/invoice",
      name: "invoice",
      component: () => import("@/views/Invoice"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/message",
      name: "message",
      component: () => import("@/views/Message"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/settinghistory",
      name: "settingshistory",
      component: () => import("@/views/SettingHistory"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/sysparams",
      name: "sysparams",
      component: () => import("@/views/SysParams"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/member",
      name: "member",
      component: () => import("@/views/Member"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/ref",
      name: "ref",
      component: () => import("@/views/Ref"),
      meta: {
        showNav: true
      }
    },

    // 景区
    {
      path: "/authsights",
      name: "authsights",
      component: () => import("@/sights/AuthSights"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/authpositions",
      name: "authpositions",
      component: () => import("@/sights/AuthPositions"),
      meta: {
        showNav: true
      }
    },
    {
      path: "/sights",
      component: () => import("@/sights/Sights"),
      children: [{
          path: "",
          redirect: 'sightlist',
          meta: {
            showNav: true,
            keepAlive: true,
          }
        },
        {
          path: "sightlist",
          name: "sightlist",
          component: () => import("@/sights/SightList"),
          meta: {
            showNav: true,
            keepAlive: true,
          }
        },
        {
          path: "sightdetail",
          name: "sightdetail",
          component: () => import("@/sights/SightDetail"),
          meta: {
            showNav: true
          }
        },
      ]
    },
    {
      path: "/sightroutes",
      component: () => import("@/sights/SightRoutes"),
      children: [{
          path: "",
          redirect: 'routelist',
          meta: {
            showNav: true
          }
        },
        {
          path: "routelist",
          name: "routelist",
          component: () => import("@/sights/RouteList"),
          meta: {
            showNav: true
          }
        },
        {
          path: "routedetail",
          name: "routedetail",
          component: () => import("@/sights/RouteDetail"),
          meta: {
            showNav: true
          }
        },
      ]
    },
    {
      path: "/tripguides",
      component: () => import("@/sights/TripGuides"),
      children: [{
          path: "",
          redirect: 'tripguidelist',
          meta: {
            showNav: true
          }
        },
        {
          path: "tripguidelist",
          name: "tripguidelist",
          component: () => import("@/sights/TripGuideList"),
          meta: {
            showNav: true
          }
        },
        {
          path: "tripguidedetail",
          name: "tripguidedetail",
          component: () => import("@/sights/TripGuideDetail"),
          meta: {
            showNav: true
          }
        },
      ]
    },
    {
      path: "/sightcompanies",
      component: () => import("@/sights/SightCompanies"),
      children: [{
          path: "",
          redirect: 'sightcompanylist'
        },
        {
          path: "sightcompanylist",
          name: "sightcompanylist",
          component: () => import("@/sights/SightCompanyList"),
          meta: {
            showNav: true
          }
        },
        {
          path: "sightcompanydetail",
          name: "sightcompanydetail",
          component: () => import("@/sights/SightCompanyDetail"),
          meta: {
            showNav: true
          }
        },
      ]
    },
    {
      path: "/serviceinfo",
      name: "serviceinfo",
      component: () => import("@/views/ServiceInfo"),
      meta: {
        showNav: true
      }
    },

  ]
});