<template>
	<div class="header-box">
		<div class="imgwrp">
			<img src="@/assets/logo.png" alt="" height="20px" />
		</div>
		<h3>十万八后台管理</h3>
		<div class="list-g">
			<div>
				{{ $store.getters.getAdmin.userName }}
			</div>
			<span @click="quit" class="quit">退出</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			quit() {
				this.$router.push({ path: "/login" });
				sessionStorage.removeItem('Authorization')
			},
		},
	};
</script>

<style scoped>
	.header-box {
		width: 100%;
		padding: 5px 60px;
		box-sizing: border-box;
		background: #333;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;
	}
	h3 {
		margin: 0px;
	}
	.quit{
		cursor: pointer;
	}
	.imgwrp{
		padding:5px;
		border-radius: 100%;
		background: #fff;
	}
</style>