<template>
  <div class="sidebar">
    <ul class="bar-container">
      <router-link to="/authsights">
        <li>
          景区审核
          <span v-if="countSight() > 0" style="color: red">({{ countSight() }})</span>
        </li>
      </router-link>
      <router-link to="/authpositions">
        <li>
          定位点审核
          <span v-if="countPosition() > 0" style="color: red">({{ countPosition() }})</span>
        </li>
      </router-link>
      <div class="line"></div>
      <router-link to="/sights">
        <li>
          景区
          <span v-if="countFeedback() > 0" style="color: red">({{ countFeedback() }})</span>
        </li>
      </router-link>
      <router-link to="/sightroutes">
        <li>
          自驾线路
          <span v-if="countRoute() > 0" style="color: red">({{ countRoute() }})</span>
        </li>
      </router-link>
      <router-link to="/tripguides">
        <li>
          攻略
          <span v-if="countTripGuide() > 0" style="color: red">({{ countTripGuide() }})</span>
        </li>
      </router-link>

      <div class="line"></div>
      <router-link to="/users">
        <li>用户</li>
      </router-link>
      <router-link to="/serviceinfo">
        <li>
          客服
          <span v-if="countServiceInfo() > 0" style="color: red">({{ countServiceInfo() }})</span>
        </li>
      </router-link>
      <div class="line"></div>

      <router-link to="/auth">
        <li>
          商家审核
          <span v-if="authSightCompany() > 0" style="color: red">({{ authSightCompany() }})</span>
        </li>
      </router-link>
      <router-link to="/sightcompanies">
        <li>商家列表</li>
      </router-link>
      <router-link to="/orders">
        <li>订单</li>
      </router-link>
      <router-link to="/payment">
        <li>
          提现
          <span v-if="withdrawsight() > 0" style="color: red">({{ withdrawsight() }})</span>
        </li>
      </router-link>

      <div class="line"></div>
      <router-link to="/message">
        <li>公共消息</li>
      </router-link>
      <router-link to="/comments">
        <li>评论</li>
      </router-link>
      <div class="line"></div>
      <router-link to="/accounts">
        <li>账户明细</li>
      </router-link>
      <router-link to="/notifyorder">
        <li>回调单</li>
      </router-link>
      <div class="line"></div>

      <div v-if="$store.getters.getAdmin.type === '0'">
        <router-link to="/sysparams">
          <li>系统参数</li>
        </router-link>
        <router-link to="/member">
          <li>成员管理</li>
        </router-link>
        <router-link to="/settinghistory">
          <li>操作记录</li>
        </router-link>
        <router-link to="/ref">
          <li>备注</li>
        </router-link>
      </div>
      <router-link to="/resetpsd">
        <li>修改密码</li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { getAuthCounts } from "@/api/api";
export default {
  data() {
    return {};
  },
  created() {
    const that = this;
    let data = {
      store: 0,
      biz: 0,
      news: 0,
      withdrawbiz: 0,
      invoice: 0,
      withdrawsight: 0,
      sight: 0,
      sightCompany: 0,
      position: 0,
      feedback: 0
    };
    this.$store.dispatch("setFirstAuth", data);
    getAuthCounts().then(res => {
      that.$store.dispatch("setFirstAuth", res.data);
    });
  },
  computed: {
    firstAuth() {
      return this.$store.getters.getFirstAuth;
    }
  },
  methods: {
    authSightCompany() {
      return parseInt(this.firstAuth.sightCompany);
    },

    withdrawsight() {
      return parseInt(this.firstAuth.withdrawsight);
    },
    countSight() {
      return this.firstAuth.sight;
    },

    countPosition() {
      return this.firstAuth.position;
    },
    countFeedback() {
      return this.firstAuth.feedback;
    },
    countRoute() {
      return this.firstAuth.route;
    },
    countTripGuide() {
      return this.firstAuth.tripGuide;
    },
    countServiceInfo() {
      return this.firstAuth.serviceInfo;
    }
  }
};
</script>

<style scoped>
.sidebar {
  position: relative;
  border-right: 1px solid darkorange;
  text-align: right;
  margin-bottom: -3000px;
  padding-bottom: 3000px;
}

.bar-container {
  list-style: none;
  padding: 0px;
}

li {
  color: #555;
  padding: 5px;
}

.line {
  display: block;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 5px 0;
}

.router-link-exact-active li {
  text-decoration: none;
  background: #113146;
  color: #fff;
}

.router-link-active li {
  text-decoration: none;
  background: #113146;
  color: #fff;
}
</style>