import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';
import "./assets/css/index.css";
import store from './store';
import VueLazyload from 'vue-lazyload';
import compressImg from "./utils/compressimg"

Vue.use(VueLazyload);
Vue.config.productionTip = false;
Vue.prototype.$compressImg = compressImg 

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');