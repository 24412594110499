import ajax from '@/utils/ajax'

export function login(data) {
    return ajax({
        url: `/api/login`,
        method: 'post',
        data
    })
}

export function resetPsd(data) {
    return ajax({
        url: `/api/auth/resetPsd`,
        method: 'post',
        data
    })
}

export function getAuthCounts(data) {
    return ajax({
        url: `/api/auth/getAuthCounts`,
        method: 'post',
        data
    })
}

//system

export function getCashPackList(data) {
    return ajax({
        url: `/api/system/getCashPackList`,
        method: 'post',
        data
    })
}

export function getCityList(data) {
    return ajax({
        url: `/api/system/getCityList`,
        method: 'post',
        data
    })
}


export function deteleCashPack(data) {
    return ajax({
        url: `/api/system/deteleCashPack`,
        method: 'post',
        data
    })
}

export function addCashPack(data) {
    return ajax({
        url: `/api/system/addCashPack`,
        method: 'post',
        data
    })
}



export function getSystemInfo(data) {
    return ajax({
        url: `/api/system/getSystemInfo`,
        method: 'post',
        data
    })
}

export function editAgreement(data) {
    return ajax({
        url: `/api/system/editAgreement`,
        method: 'post',
        data
    })
}


export function editCompanyInfo(data) {
    return ajax({
        url: `/api/system/editCompanyInfo`,
        method: 'post',
        data
    })
}


//memeber



export function getMemberlist(data) {
    return ajax({
        url: `/api/member/getMemberlist`,
        method: 'post',
        data
    })
}


export function createAdminMemb(data) {
    return ajax({
        url: `/api/member/createAdminMemb`,
        method: 'post',
        data
    })
}

export function deleteUser(data) {
    return ajax({
        url: `/api/member/deleteUser`,
        method: 'post',
        data
    })
}

export function memberResetPsd(data) {
    return ajax({
        url: `/api/member/resetPsd`,
        method: 'post',
        data
    })
}

//img



export function editSysImg(data) {
    return ajax({
        url: `/api/system/editSysImg`,
        method: 'post',
        data
    })
}

//message


export function getMessageList(data) {
    return ajax({
        url: `/api/message/getMessageList`,
        method: 'post',
        data
    })
}

export function getMessageTemplate(data) {
    return ajax({
        url: `/api/message/getMessageTemplate`,
        method: 'post',
        data
    })
}

export function editMessageTemplate(data) {
    return ajax({
        url: `/api/message/editMessageTemplate`,
        method: 'post',
        data
    })
}

export function createMessage(data) {
    return ajax({
        url: `/api/message/createMessage`,
        method: 'post',
        data
    })
}


export function closeMessage(data) {
    return ajax({
        url: `/api/message/closeMessage`,
        method: 'post',
        data
    })
}

//users



export function getAllUserAdmin(data) {
    return ajax({
        url: `/api/admin/getAllUserAdmin`,
        method: 'post',
        data
    })
}


export function getUserInfoAdmin(data) {
    return ajax({
        url: `/api/user/getUserInfoAdmin`,
        method: 'post',
        data
    })
}



export function modifyUserState(data) {
    return ajax({
        url: `/api/user/modifyUserState`,
        method: 'post',
        data
    })
}

export function getUserMessage(data) {
    return ajax({
        url: `/api/message/getUserMessage`,
        method: 'post',
        data
    })
}

//操作记录
export function getSettingHistory(data) {
    return ajax({
        url: `/api/auth/getSettingHistory`,
        method: 'post',
        data
    })
}



// user

export function adminGetUserSightInfo(data) {
    return ajax({
        url: `/api/user/adminGetUserSightInfo`,
        method: 'post',
        data
    })
}

export function adminGetUserRouteInfo(data) {
    return ajax({
        url: `/api/user/adminGetUserRouteInfo`,
        method: 'post',
        data
    })
}


//biz

export function getWithdrawBiz(data) {
    return ajax({
        url: `/api/biz/getWithdrawBiz`,
        method: 'post',
        data
    })
}


export function adminGetOrderList(data) {
    return ajax({
        url: `/api/order/adminGetOrderList`,
        method: 'post',
        data
    })
}

export function getOrderBySn(data) {
    return ajax({
        url: `/api/order/getOrderBySn`,
        method: 'post',
        data
    })
}


export function authComment(data) {
    return ajax({
        url: `/api/comment/authComment`,
        method: 'post',
        data
    })
}

export function getNotifyorders(data) {
    return ajax({
        url: `/api/account/getNotifyorders`,
        method: 'post',
        data
    })
}

export function auditInvoice(data) {
    return ajax({
        url: `/api/invoice/auditInvoice`,
        method: 'post',
        data
    })
}


export function adminGetComments(data) {
    return ajax({
        url: `/api/admin/adminGetComments`,
        method: 'post',
        data
    })
}


export function getMemberStores(data) {
    return ajax({
        url: `/api/member/getMemberStores`,
        method: 'post',
        data
    })
}

export function getAccount(data) {
    return ajax({
        url: `/api/account/getAccount`,
        method: 'post',
        data
    })
}


export function getAdminInvoiceList(data) {
    return ajax({
        url: `/api/invoice/getAdminInvoiceList`,
        method: 'post',
        data
    })
}

export function getBizMsg(data) {
    return ajax({
        url: `/api/message/getBizMessageList`,
        method: 'post',
        data
    })
}


export function getAuthRefund(data) {
    return ajax({
        url: `/api/refund/getAuthRefund`,
        method: 'post',
        data
    })
}


export function adminGetSubaccount(data) {
    return ajax({
        url: `/api/account/adminGetSubaccount`,
        method: 'post',
        data
    })
}

export function getPromotionCouponHistory(data) {
    return ajax({
        url: `/api/promotion/getPromotionCouponHistory`,
        method: 'post',
        data
    })
}

export function updateAccountState(data) {
    return ajax({
        url: `/api/account/updateAccountState`,
        method: 'post',
        data
    })
}

export function adjustAccountApi(data) {
    return ajax({
        url: `/api/account/adjustAccount`,
        method: 'post',
        data
    })
}

export function getPaymentList(data) {
    return ajax({
        url: `/api/withdraw/getPaymentList`,
        method: 'post',
        data
    })
}

export function refusePayment(data) {
    return ajax({
        url: `/api/withdraw/refusePayment`,
        method: 'post',
        data
    })
}


export function getBizDetail(data) {
    return ajax({
        url: `/api/biz/getBizDetail`,
        method: 'post',
        data
    })
}

export function updateBizState(data) {
    return ajax({
        url: `/api/biz/updateBizState`,
        method: 'post',
        data
    })
}


export function deleteFile(data) {
    return ajax({
        url: `/api/deleteFile`,
        method: 'post',
        data
    })
}


export function adminUpdatePhone(data) {
    return ajax({
        url: `/api/adminUpdatePhone`,
        method: 'post',
        data
    })
}

// 景区

export function getSightsForAuth(data) {
    return ajax({
        url: `/api/sight/getSightsForAuth`,
        method: 'post',
        data
    })
}

export function authSight(data) {
    return ajax({
        url: `/api/sight/authSight`,
        method: 'post',
        data
    })
}

export function rejectSight(data) {
    return ajax({
        url: `/api/sight/rejectSight`,
        method: 'post',
        data
    })
}
export function adminDeleteSight(data) {
    return ajax({
        url: `/api/sight/adminDeleteSight`,
        method: 'post',
        data
    })
}
export function getPositionsForAuth(data) {
    return ajax({
        url: `/api/sight/getPositionsForAuth`,
        method: 'post',
        data
    })
}

export function authPosition(data) {
    return ajax({
        url: `/api/sight/authPosition`,
        method: 'post',
        data
    })
}

export function getEdits(data) {
    return ajax({
        url: `/api/sight/getEdits`,
        method: 'post',
        data
    })
}

export function deleteEdit(data) {
    return ajax({
        url: `/api/sight/deleteEdits`,
        method: 'post',
        data
    })
}

export function updateSight(data) {
    return ajax({
        url: `/api/sight/updateSight`,
        method: 'post',
        data
    })
}
export function adminGetSightTypes(data) {
    return ajax({
        url: `/api/sight/adminGetSightTypes`,
        method: 'post',
        data
    })
}

export function adminSearchSight(data) {
    return ajax({
        url: `/api/sight/adminSearchSight`,
        method: 'post',
        data
    })
}
export function updatePosition(data) {
    return ajax({
        url: `/api/sight/updatePosition`,
        method: 'post',
        data
    })
}

export function updateTickets(data) {
    return ajax({
        url: `/api/sight/updateTickets`,
        method: 'post',
        data
    })
}

export function rejectEdit(data) {
    return ajax({
        url: `/api/sight/rejectEdit`,
        method: 'post',
        data
    })
}
export function rejectPosition(data) {
    return ajax({
        url: `/api/sight/rejectPosition`,
        method: 'post',
        data
    })
}
export function getAdminRoutes(data) {
    return ajax({
        url: `/api/sight/getAdminRoutes`,
        method: 'post',
        data
    })
}

export function getAdminTripGuides(data) {
    return ajax({
        url: `/api/sight/getAdminTripGuides`,
        method: 'post',
        data
    })
}

export function adminGetTripGuide(data) {
    return ajax({
        url: `/api/sight/adminGetTripGuide`,
        method: 'post',
        data
    })
}

export function getAdminSights(data) {
    return ajax({
        url: `/api/sight/getAdminSights`,
        method: 'post',
        data
    })
}
export function adminGetRoute(data) {
    return ajax({
        url: `/api/sight/adminGetRoute`,
        method: 'post',
        data
    })
}
export function authRouteAndGuide(data) {
    return ajax({
        url: `/api/sight/authRouteAndGuide`,
        method: 'post',
        data
    })
}


export function getSightCompaniesForAuth(data) {
    return ajax({
        url: `/api/sightadmin/getSightCompaniesForAuth`,
        method: 'post',
        data
    })
}

export function auditSightCompany(data) {
    return ajax({
        url: `/api/sightadmin/auditSightCompany`,
        method: 'post',
        data
    })
}
export function adminGetSightDetail(data) {
    return ajax({
        url: `/api/sightadmin/adminGetSightDetail`,
        method: 'post',
        data
    })
}
export function imgCheckedAdmin(data) {
    return ajax({
        url: `/api/sightadmin/imgCheckedAdmin`,
        method: 'post',
        data
    })
}

export function adminGetSightTickets(data) {
    return ajax({
        url: `/api/sightadmin/adminGetSightTickets`,
        method: 'post',
        data
    })
}
export function adminSaveTicketIntro(data) {
    return ajax({
        url: `/api/sightadmin/adminSaveTicketIntro`,
        method: 'post',
        data
    })
}
export function adminGetSightLiveInfo(data) {
    return ajax({
        url: `/api/sightadmin/adminGetSightLiveInfo`,
        method: 'post',
        data
    })
}

export function adminDeleteSightLiveInfo(data) {
    return ajax({
        url: `/api/sightadmin/adminDeleteSightLiveInfo`,
        method: 'post',
        data
    })
}

export function adminSetTicket(data) {
    return ajax({
        url: `/api/sightadmin/adminSetTicket`,
        method: 'post',
        data
    })
}

export function AdminGetSightCompanyList(data) {
    return ajax({
        url: `/api/sightadmin/AdminGetSightCompanyList`,
        method: 'post',
        data
    })
}

export function AdminGetSightCompanyDetail(data) {
    return ajax({
        url: `/api/sightadmin/AdminGetSightCompanyDetail`,
        method: 'post',
        data
    })
}

export function adminUpdateSightCompanyState(data) {
    return ajax({
        url: `/api/sightadmin/adminUpdateSightCompanyState`,
        method: 'post',
        data
    })
}

export function getSightFeedBack(data) {
    return ajax({
        url: `/api/sightadmin/getSightFeedBack`,
        method: 'post',
        data
    })
}
export function handleFeedback(data) {
    return ajax({
        url: `/api/sightadmin/handleFeedback`,
        method: 'post',
        data
    })
}

export function AdminReplyServiceInfo(data) {
    return ajax({
        url: `/api/system/AdminReplyServiceInfo`,
        method: 'post',
        data
    })
}

export function adminGetServiceInfo(data) {
    return ajax({
        url: `/api/system/adminGetServiceInfo`,
        method: 'post',
        data
    })
}