import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: '',
    firstAuth: null,
    secondAuth: null,
  },
  mutations: {
    admin(state, payLoad) {
      state.admin = payLoad;
      sessionStorage.setItem('admin', JSON.stringify(payLoad));
    },
    firstAuth(state, payLoad) {
      state.firstAuth = payLoad;
      sessionStorage.setItem('firstAuth', JSON.stringify(payLoad));
    },
    secondAuth(state, payLoad) {
      state.secondAuth = payLoad;
      sessionStorage.setItem('secondAuth', JSON.stringify(payLoad));
    }

  },
  actions: {
    setAdmin(context, payLoad) {
      context.commit('admin', payLoad);
    },
    setFirstAuth(context, payLoad) {
      context.commit('firstAuth', payLoad);
    },
    setSecondAuth(context, payLoad) {
      context.commit('secondAuth', payLoad);
    },
  },
  getters: {
    getAdmin(state) {
      state.admin = JSON.parse(sessionStorage.getItem('admin'));
      return state.admin;
    },
    getFirstAuth(state) {
      state.firstAuth = JSON.parse(sessionStorage.getItem('firstAuth'));
      return state.firstAuth;
    },
    getSecondAuth(state) {
      state.secondAuth = JSON.parse(sessionStorage.getItem('secondAuth'));
      return state.secondAuth;
    },
  },
  modules: {},
});