<template>
  <div id="app">
    <div v-if="$route.meta.showNav">
      <headerbar />
      <div class="app-container">
        <el-row>
          <el-col :span="2">
            <sidebar />
          </el-col>
          <el-col :span="22" style="padding:10px 30px">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import headerbar from "../src/components/HeaderBar";
import sidebar from "../src/components/SideBar";

export default {
  name: "App",
  components: {
    headerbar,
    sidebar
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}
.app-container {
  margin-bottom: 50px;
  overflow: hidden;
}
</style>
